<template>
  <div v-frag>
    <v-container fluid>
      <v-alert
        v-model='alertOptions.show'
        :color='alertOptions.color'
        class='rounded-xl'
        dark
        dense
        dismissible
        icon='mdi-check-circle-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>{{ alertOptions.title }}</h3>
        <p>{{ alertOptions.body }}</p>
      </v-alert>

      <v-row
        v-if='!charityBankAccount.status_account'
        align='center'
        align-content='center'
        justify='center'
      >
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title>Important!</v-card-title>
            <v-card-text>
              The employees won't be able to donate Fundraising Needs until bank
              account is verified.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title class=''>
              <v-row>
                <v-col cols='12' lg='5' md='5' sm='12' xl='5' xs='12'>
                  {{ sections.fundraising_needs_fundraising_needs || 'Fundraising Needs title' }}
                </v-col>

                <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
                  <v-text-field
                    v-model='search'
                    class='rounded-lg'
                    dense
                    filled
                    hide-details
                    placeholder='Search'
                    prepend-inner-icon='mdi-magnify'
                    rounded
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='3' md='3' sm='12' xl='3' xs='12'>
                  <v-btn
                    block
                    class='white--text text-capitalize'
                    color='blue'
                    height='100%'
                    outlined
                    @click="openDialog('New Need')"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add New Need
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-container fluid>
              <v-data-table
                :headers='headers'
                :items='desserts'
                :loading='loading'
                :search='search'
                class='elevation-1'
                item-key='id'
              >
                <template v-slot:[`item.need`]='{ item }'>
                  <span class='eventNeed' @click='clickEvent(item)'>{{
                      item.need
                    }}</span>
                </template>

                <template v-slot:[`item.mount_required`]='{ item }'>
                  <span> {{ formatMoney(item.mount_required) }} </span>
                </template>

                <template v-slot:[`item.total_donations`]='{ item }'>
                  <span> {{ formatMoney(item.total_donations) }} </span>
                </template>

                <template v-slot:[`item.end`]='{ item }'>
                  <span> {{ formatToDate(item.end) }} </span>
                </template>

                <template v-slot:[`item.actions`]='{ item }'>
                  <v-icon
                    class='mr-2 my-1 pa-2 blue white--text rounded-sm'
                    small
                    @click='editEvent(item)'
                  >
                    mdi-pencil
                  </v-icon>

                  <v-icon
                    class='mr-2 my-1 pa-2 red white--text rounded-sm'
                    small
                    @click='deleteEvent(item)'
                  >
                    mdi-close-thick
                  </v-icon>
                </template>
              </v-data-table>

              <v-dialog
                v-model='dialog'
                max-width='1000px'
                persistent
                scrollable
              >
                <v-card>
                  <v-card-title>
                    <span class='headline'>{{ dialogTitle }}</span>
                  </v-card-title>

                  <v-card-text class='py-2 px-4'>
                    <v-container>
                      <v-form
                        ref='form'
                        v-model='valid'
                        :lazy-validation='true'
                        class='modalForm'
                      >
                        <v-row class='imageRow'>
                          <v-col>
                            <v-avatar color='grey' size='100'>
                              <v-img :src='image'></v-img>
                            </v-avatar>
                          </v-col>
                          <v-col md='6' sm='6'>
                            <v-file-input
                              v-model='fileSelected'
                              accept='image/png, image/jpeg, image/bmp'
                              chips
                              dense
                              label='Event Avatar'
                              outlined
                              placeholder='Add a file or drop it here'
                              prepend-icon='mdi-camera'
                              show-size
                              @change='selectFile'
                            ></v-file-input>
                          </v-col>
                        </v-row>
                        <v-row md='4' sm='6'>
                          <v-col>
                            <p class='font-weight-bold label'>Need</p>
                            <v-text-field
                              ref='charityNameInput'
                              v-model='editedItem.need'
                              :rules='needRules'
                              :value='editedItem.logo'
                              outlined
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <p class='font-weight-bold label'>Funds Needed</p>
                            <v-currency-field
                              v-model='editedItem.mount_required'
                              outlined
                              prefix='$'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md='6' sm='12'>
                            <v-menu
                              v-model='menuDateStart'
                              :close-on-content-click='false'
                              :nudge-right='40'
                              min-width='auto'
                              offset-y
                              transition='scale-transition'
                            >
                              <template v-slot:activator='{ on, attrs }'>
                                <p class='font-weight-bold label'>Start</p>
                                <v-text-field
                                  v-model='editedItem.start'
                                  :rules='startDateRules'
                                  prepend-icon='mdi-calendar'
                                  readonly
                                  v-bind='attrs'
                                  v-on='on'
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model='editedItem.start'
                                @input='menuDateStart = false'
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col>
                            <v-menu
                              v-model='menuDateDeadline'
                              :close-on-content-click='false'
                              :nudge-right='40'
                              min-width='auto'
                              offset-y
                              transition='scale-transition'
                            >
                              <template v-slot:activator='{ on, attrs }'>
                                <p class='font-weight-bold label'>Deadline</p>
                                <v-text-field
                                  v-model='editedItem.end'
                                  :rules='[validateEndDate]'
                                  prepend-icon='mdi-calendar'
                                  readonly
                                  v-bind='attrs'
                                  v-on='on'
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model='editedItem.end'
                                @input='menuDateDeadline = false'
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <p class='font-weight-bold label'>Description</p>
                            <v-textarea
                              v-model='editedItem.description'
                              outlined
                              rows='3'
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class='d-flex direction-row justify-center'>
                    <v-btn
                      class='px-14 ma-4 font-weight-bold'
                      color='blue'
                      large
                      outlined
                      @click='closeDialog'
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :loading='loadingSubmit'
                      class='px-14 ma-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click='saveEvent'
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model='dialogDelete' max-width='500px'>
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text' color='error' large left
                    >mdi-alert
                    </v-icon
                    >
                    Delete Event
                  </v-card-title>
                  <v-card-subtitle>
                    <b>You are about to delete the following event:</b>
                  </v-card-subtitle>
                  <p class='ml-8 py-4 font-weight-bold secondary--text'>
                    {{ deletedItem.need }}
                  </p>
                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click='dialogDelete = false'
                    >
                      Cancel
                    </v-btn
                    >
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click='deleteEventConfirm'
                    >
                      Confirm
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model='dialogCrop'
      max-width='500px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <clipper-fixed
            ref='clipper'
            :round='true'
            :src='imagenPreview'
          ></clipper-fixed>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class='px-14 mx-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='cancelCrop'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='cropImage'
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import donationsService from '../../services/donations/index'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loadingSubmit: false,
      charityBankAccount: {
        status_account: 1
      },
      dialog: false,
      dialogTitle: '',
      dialogDelete: false,
      alertOptions: {
        show: false
      },
      menuDateStart: false,
      menuDateDeadline: false,
      loading: false,
      valid: true,
      totalDesserts: 5,
      search: '',
      options: {},
      headers: [
        { text: 'Need', align: 'start', value: 'need', divider: true },
        { text: 'Funds Needed', value: 'mount_required', divider: true },
        { text: 'Amount Raised', value: 'total_donations', divider: true },
        { text: 'Deadline', value: 'end', divider: true },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      desserts: [],
      editedItem: {
        description: ''
      },
      editedIndex: -1,
      deletedItem: {},
      fileSelected: '',
      changeFile: false,
      currency: '$',
      image: '',
      needRules: [
        (v) => !!v || 'Need is required',
        (v) => (v && v.length <= 25) || 'Need must be less than 25 characters'
      ],
      startDateRules: [
        (v) => !!v || 'Field is required'
      ],
      dialogCrop: false,
      imagenPreview: '',
      fileType: ''
    }
  },
  computed: {
    ...mapState('cmsStore', ['sections']),
    isValidForm() {
      return this.$refs.form.validate()
    }
  },
  methods: {
    async initialize() {
      this.loading = true
      try {
        const res = await donationsService.getDonations()
        this.charityBankAccount = this.$store.getters.charityBankAccount
        this.desserts = res.filter((event) => {
          if (event.event_type_id == 2) {
            return event
          }
        })
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    async saveEvent() {
      if (this.isValidForm) {
        const data = {
          need: this.editedItem.need,
          mount_required: this.editedItem.mount_required ? this.editedItem.mount_required : 0,
          start: new Date(this.editedItem.start).toISOString(),
          end: new Date(this.editedItem.end).toISOString(),
          description: this.editedItem.description
        }
        this.loadingSubmit = true

        this.editedIndex > -1
          ? await this.updateEvent(data)
          : await this.createEvent(data)

        this.loadingSubmit = false
      }
    },
    async updateEvent(data) {
      if (this.changeFile) data.logo = this.fileSelected
      data.charityEventId = this.editedItem.id

      try {
        const res = await donationsService.updateEventDonation(data)
        res.mount_required = Number(res.mount_required)
        Object.assign(this.desserts[this.editedIndex], res)
        this.closeDialog()
        this.openAlert('updated')
      } catch (e) {
        this.closeDialog()
        this.openAlert('error')
      }
    },
    async createEvent(data) {
      data.logo = this.fileSelected
      data.fileType = this.fileType

      try {
        const res = await donationsService.createEvent(data)
        res.mount_required = Number(res.mount_required)
        res.total_donations = 0
        this.desserts.push(res)
        this.closeDialog()
        this.openAlert('created')
      } catch (err) {
        this.openAlert('error')
      }
    },
    selectFile(file) {
      if (file) {
        this.changeFile = true
        this.fileSelected = file
        this.fileType = file.type
        this.imagenPreview = URL.createObjectURL(file)
        this.dialogCrop = true
      } else {
        this.image = ''
        this.imagenPreview = ''
      }
    },
    editEvent(item) {
      item.start = this.formatToDate(item.start)
      item.end = this.formatToDate(item.end)
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.image = item.logo
      this.imagenPreview = item.logo
      this.fileSelected = this.formatAvatar(item.logo)
      this.dialogTitle = 'Edit Need'
      this.dialog = true
    },
    deleteEvent(item) {
      this.deletedIndex = this.desserts.indexOf(item)
      this.deletedItem = Object.assign({}, item)
      this.dialogDelete = !this.dialogDelete
    },
    deleteEventConfirm() {
      donationsService
        .deleteEvent(this.deletedItem.id)
        .then(() => {
          this.desserts.splice(this.deletedIndex, 1)
          this.openAlert('deleted')
        })
        .catch(() => this.openAlert('error'))
      this.dialogDelete = !this.dialogDelete
    },
    clickEvent(value) {
      this.$store.dispatch('charityEventDetail', value)
      this.$router.push(`/charity-event-detail/${ value.id }`)
    },
    openDialog(title) {
      this.valid = true
      this.image = ''
      this.fileSelected = null
      this.dialogTitle = title
      this.dialog = true
    },
    closeDialog() {
      this.$refs.form.reset()
      this.editedItem = {
        description: ''
      }
      this.editedIndex = -1
      this.dialog = !this.dialog
      this.image = ''
      this.fileSelected = null
      this.changeFile = false
      this.valid = true
    },
    formatMoney: function (value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    },
    formatToDate: (value) => {
      if (value) {
        const date = new Date(value).toISOString()
          .substr(0, 10)
        return `${ date }`
      }
    },
    formatAvatar(value) {
      if (typeof value == 'string' && value != '') {
        let fileName = value.split('/')
        fileName = fileName[fileName.length - 1]
        return new File([`${ fileName }`], `${ fileName }`, {
          type: 'text/plain'
        })
      } else {
        return ''
      }
    },
    openAlert(operation) {
      const options = {
        show: true,
        color: 'success',
        title: '',
        body: ''
      }
      switch (operation) {
        case 'created':
          options.title = 'Event created!'
          options.body = 'Event was successfully created.'
          break
        case 'updated':
          options.title = 'Event updated!'
          options.body = 'Event was successfully updated.'
          break
        case 'deleted':
          options.title = 'Event deleted!'
          options.body = 'Event was successfully deleted.'
          break
        case 'error':
          options.color = 'error'
          options.title = 'Something went wrong!'
          options.body = 'Something has gone wrong, please try again.'
          break
        default:
          break
      }
      this.alertOptions = options
    },
    validateEndDate() {
      const endDate = new Date(this.editedItem.end)
      const startDate = new Date(this.editedItem.start)
      if (this.editedItem.end && endDate > startDate) {
        return true
      }
      if (endDate <= startDate) {
        return 'Ending date must be after Starting Date'
      } else {
        return 'Field is Required'
      }
    },
    cropImage() {
      this.$refs.clipper.clip()
        .toBlob((blob) => {
          this.image = URL.createObjectURL(blob)
          this.imagePreview = URL.createObjectURL(blob)
        })
      this.dialogCrop = false
    },
    cancelCrop() {
      this.fileSelected = null
      this.dialogCrop = false
    }
  },
  created() {
    this.initialize()
  }
}
</script>

<style>
.modalForm .label {
  margin-bottom: 2px;
}
.modalForm .v-card__title {
  margin: 0;
}
.modalForm .v-input__slot {
  min-height: 42px !important;
}
.modalForm .col {
  padding-top: 0;
  padding-bottom: 0;
}
.eventNeed {
  text-decoration: underline;
}
.eventNeed:hover {
  color: #000;
  cursor: pointer;
}
.imageRow {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}
</style>
